var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('div',{staticClass:"container"},[_c('img',{staticClass:"image",attrs:{"src":require("@/assets/img/login_cover.png")}}),_c('div',{staticClass:"login-view"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"logo-view"},[_c('img',{staticClass:"logo",attrs:{"alt":"logo","src":_vm.pcLogo}})]),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.onSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'name',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入用户名',
                      whitespace: true,
                    },
                  ],
                },
              ]),expression:"[\n                'name',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入用户名',\n                      whitespace: true,\n                    },\n                  ],\n                },\n              ]"}],attrs:{"autocomplete":"autocomplete","size":"large","placeholder":"请输入用户名"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'password',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入密码',
                      whitespace: true,
                    },
                  ],
                },
              ]),expression:"[\n                'password',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入密码',\n                      whitespace: true,\n                    },\n                  ],\n                },\n              ]"}],attrs:{"size":"large","placeholder":"请输入密码","autocomplete":"autocomplete","type":"password"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'code',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入验证码',
                      whitespace: true,
                    },
                  ],
                },
              ]),expression:"[\n                'code',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入验证码',\n                      whitespace: true,\n                    },\n                  ],\n                },\n              ]"}],attrs:{"size":"large","placeholder":"请输入验证码","max-length":6}},[_c('a-icon',{attrs:{"slot":"prefix","type":"safety"},slot:"prefix"}),_c('img',{staticStyle:{"width":"122px","height":"38px"},attrs:{"slot":"addonAfter","src":_vm.codePicture},on:{"click":_vm.loadImageCode},slot:"addonAfter"})],1)],1),_c('div',{staticStyle:{"width":"100%","display":"flex","justify-content":"flex-end"}},[_c('a',{attrs:{"href":"#"},on:{"click":_vm.forgetPwd}},[_vm._v("忘记密码")])]),_c('a-form-item',[_c('a-button',{staticStyle:{"width":"100%","margin-top":"24px"},attrs:{"loading":_vm.logging,"size":"large","htmlType":"submit","type":"primary"}},[_vm._v(" 登录 ")])],1)],1)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }