<template>
  <a-modal
    title="验证"
    :visible="visible"
    destroyOnClose
    :afterClose="onAfterClose"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-input
      v-model.trim="code"
      size="large"
      placeholder="请输入图中验证码"
      :max-length="6"
    >
      <a-icon slot="prefix" type="safety" />
      <img
        slot="addonAfter"
        :src="codePicture"
        style="width: 122px; height: 38px"
        @click="loadImageCode"
      />
    </a-input>
  </a-modal>
</template>

<script>
export default {
  model: {
    prop: "visible",
    event: "changVisible",
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      codePictureKey: "",
      codePicture: "",
      code: "",
    };
  },
  mounted() {
    this.loadImageCode();
  },
  methods: {
    loadImageCode() {
      this.codePictureKey = Date.parse(new Date());
      this.codePicture =
        process.env.VUE_APP_API_BASE_URL +
        "/pcapi/pc/captcha?key=" +
        this.codePictureKey;
    },
    handleOk() {
      if (!this.code) {
        return this.$message.error("请输入验证码");
      }
      this.$emit("confirm", {
        key: this.codePictureKey,
        code: this.code,
      });
      this.changVisible(false);
    },
    handleCancel() {
      this.$emit("cancel");
      this.changVisible(false);
    },
    changVisible(e) {
      this.$emit("changVisible", e);
    },
    onAfterClose() {
      this.code = "";
    },
  },
};
</script>

<style lang="scss" scoped></style>
