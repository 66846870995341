<template>
  <div class="page">
    <div class="container">
      <img class="image" src="@/assets/img/login_cover.png" />
      <div class="login-view">
        <div class="content">
          <div class="logo-view">
            <img alt="logo" class="logo" :src="pcLogo" />
          </div>
          <a-form @submit="onSubmit" :form="form">
            <a-form-item>
              <a-input
                autocomplete="autocomplete"
                size="large"
                placeholder="请输入用户名"
                v-decorator="[
                  'name',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入用户名',
                        whitespace: true,
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="user" />
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-input
                size="large"
                placeholder="请输入密码"
                autocomplete="autocomplete"
                type="password"
                v-decorator="[
                  'password',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入密码',
                        whitespace: true,
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="lock" />
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-input
                size="large"
                placeholder="请输入验证码"
                :max-length="6"
                v-decorator="[
                  'code',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入验证码',
                        whitespace: true,
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="safety" />
                <img
                  slot="addonAfter"
                  :src="codePicture"
                  style="width: 122px; height: 38px"
                  @click="loadImageCode"
                />
              </a-input>
            </a-form-item>
            <div style="width: 100%; display: flex; justify-content: flex-end">
              <a href="#" @click="forgetPwd">忘记密码</a>
            </div>
            <a-form-item>
              <a-button
                :loading="logging"
                style="width: 100%; margin-top: 24px"
                size="large"
                htmlType="submit"
                type="primary"
              >
                登录
              </a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import notification from "ant-design-vue/es/notification";
import { setAuthorization } from "@/utils/request";
import { setting, pwdLogin } from "@/api/login";
import { timeFix } from "@/utils/util";
import { scoket } from "@/utils/socket";

export default {
  data() {
    return {
      loginBtn: false,
      logging: false,
      error: "",
      form: this.$form.createForm(this),
      systemName: "",
      pcLogo: "",
      tripartiteUid: "",
      codePictureKey: "",
      codePicture: "",
    };
  },
  created() {
    this.handleLogin();
  },
  mounted() {
    setting().then((res) => {
      if (res.data.status == 200) {
        this.systemName = res.data.data.setting.site_name;
        this.pcLogo = res.data.data.setting.pc_logo;
        localStorage.setItem("systemName", res.data.data.setting.site_name);
        localStorage.setItem("pcLogo", res.data.data.setting.pc_logo);
        localStorage.setItem("headImg", res.data.data.setting.h5_avatar);
      } else {
        this.$message.error(res.data.msg);
      }
    });
    this.loadImageCode();
  },
  methods: {
    ...mapActions(["Login"]),
    loadImageCode() {
      this.codePictureKey = Date.parse(new Date());
      this.codePicture =
        process.env.VUE_APP_API_BASE_URL +
        "/pcapi/pc/captcha?key=" +
        this.codePictureKey;
    },
    // 忘记密码
    forgetPwd() {
      this.$router.push({ path: "/forgetPwd" });
    },
    onSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err) => {
        if (err) {
          return;
        }
        const name = this.form.getFieldValue("name");
        const password = this.form.getFieldValue("password");
        const code = this.form.getFieldValue("code");

        const tripartiteUid = this.tripartiteUid;
        this.logging = true;
        pwdLogin({
          account: name,
          password: password,
          code,
          key: this.codePictureKey,
          tripartiteUid: tripartiteUid,
        })
          .then((res) => {
            if (res.data.status == 200) {
              localStorage.setItem("token", res.data.data.token);
              localStorage.setItem("uid", res.data.data.userinfo.uid);
              localStorage.setItem("expireAt", res.data.data.exp_time * 1000);
              const token = res.data.data.token;
              const expireAt = res.data.data.exp_time * 1000;
              setAuthorization({ token, expireAt: new Date(Number(expireAt)) });
              this.loginSuccess();
              this.sendUid(res.data.data.userinfo.uid);
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .finally(() => {
            this.logging = false;
          });
      });
    },
    sendUid(id) {
      scoket.then((ws) => {
        ws.send({ type: "binduid", uid: id });
      });
    },
    // handler
    handleLogin() {
      const { Login } = this;
      // var token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJ7XCJjcmVhdGVUaW1lXCI6XCIyMDIxLTExLTE0IDE1OjE3OjQxXCIsXCJjcmVhdG9ySWRcIjoxLFwiY3JlYXRvck5hbWVcIjpcIui2hee6p-euoeeQhuWRmFwiLFwiaWRcIjoxNjcyLFwibGFzdExvZ2luVGltZVwiOlwiMjAyMy0wMi0yMiAxNzo0NDo0OVwiLFwibW9iaWxlXCI6XCIxMzA2NjAxNDUxNlwiLFwicm9sZVwiOntcImNyZWF0ZVRpbWVcIjpcIjIwMjMtMDItMjIgMTE6MzU6MTVcIixcImNyZWF0b3JcIjoxNjcyLFwiY3JlYXRvck5hbWVcIjpcIua1i-ivleWKoOebn-WVhlwiLFwiaWRcIjoxLFwiaXNVc2VcIjoxLFwibmFtZVwiOlwi6LaF57qn566h55CG5ZGYXCIsXCJyb2xlVHlwZVwiOjB9LFwic3RhdGVcIjoxLFwic3lzTWFuYWdlckluZm9cIjp7XCJlbWFpbFwiOlwiXCIsXCJmZlR5cGVcIjoxLFwiZ2VuZGVyXCI6MSxcImpvYlR5cGVcIjoxLFwibWFuYWdlcklkXCI6MTY3MixcInBvc2l0aW9uXCI6MTA5LFwicHJvamVjdFwiOlwiMSwyLDMsNVwiLFwicmVhbE5hbWVcIjpcIua1i-ivleWKoOebn-WVhlwifSxcInVjTWFuYWdlcklkXCI6MTY3Mn0iLCJpYXQiOjE2NzcxMTUwMzAsImV4cCI6MTY3NzcxOTgzMH0.LznHxlIHpisp5GWU2ziR9PqCTIWCGP4DrPtBvHj5PvNo5GQSQ_2eTeeUzuU9pqlivxev88cUYtvEKopvGrVuMw"
      console.log(this.$route);
      let token = this.$route.query.token;
      // this.isHasToken = !!token
      if (token) {
        Login(token)
          .then((res) => {
            // this.form.setFieldValue('tripartiteUid',res.tripartiteUid)
            this.tripartiteUid = res.tripartiteUid;
            // let expireAt = res.exp_time * 1000
            // localStorage.setItem('token', res.token)
            // localStorage.setItem('expireAt', expireAt)
            // setAuthorization({ token: res.token, expireAt: new Date(Number(expireAt)) })
            // this.loginSuccess(res)
          })
          .catch((err) => {
            notification.error({
              message: "错误",
              description: err,
              duration: null,
            });
          });
      }
      // else {
      //   notification.error({
      //     message: '错误',
      //     description: '登录权限异常，请从工作台重新进入',
      //   })
      // }
    },

    loginSuccess() {
      this.$router.replace({ path: "/bookBuy/allGoods" });
      // 延迟 1 秒显示欢迎信息
      setTimeout(() => {
        this.$notification.success({
          message: "欢迎",
          description: `${timeFix()}，欢迎回来`,
        });
      }, 1000);
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  display: flex;
  width: 100%;
  background: #ebf5f1;
  background-size: cover;
  background-position: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: auto;
}

.container {
  width: auto;
  height: 400px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .image {
    display: none;
  }
}
.image {
  width: 510px;
  height: 100%;
  object-fit: contain;
  border-radius: 12px 0px 0px 12px;
}
.login-view {
  flex: none;
  width: 400px;
  height: 100%;
  background-color: white;
  border-radius: 12px;
  position: relative;
}
.content {
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .logo-view {
    height: 68px;
    position: relative;
    margin-bottom: 22px;

    .logo {
      height: 68px;
      vertical-align: top;
      position: absolute;
      left: 50%;
      transform: translate(-50%);
    }
  }
}
</style>
