import { getRequest, postRequest } from "@/utils/request";

// export const login = (params) => {
//   return postRequest('pc/login', params)
// }
export const login = (token) => {
  return getRequest("pc/dlLogin?token=" + token);
};
export const pwdLogin = (data) => {
  return postRequest("pc/pwdLogin", data);
};
export const setting = () => {
  return getRequest("pc/system/setting");
};
export const userInfo = (params) => {
  return getRequest("user/get/info", params);
};

export const smsCode = (data) => {
  return getRequest("user/loginPwd/sms", data);
};

export const editPassword = (data) => {
  return postRequest("user/editLoginPwd", data);
};

// 忘记密码-获取短信key
export const getSmsKey = () => {
  return getRequest("pc/verify_code");
};
// 忘记密码-发送短信
export const sendSms = (data) => {
  return postRequest("pc/sms", data);
};
// 忘记密码-重置密码
export const resetPassword = (data) => {
  return postRequest("pc/reset", data);
};
