import {
  postRequest,
  getRequest,
  putRequest,
  deleteRequest,
} from "@/utils/request";

//可开票订单
export const invoiceList = (params) => {
  return getRequest("invoice/list", params);
};
//获取可开票信息
export const invoiceAll = () => {
  return getRequest("invoice/invoiceAll");
};
//获取可开票信息
export const orderBatchApply = (params) => {
  return postRequest("invoice/orderBatchApply", params);
};

//我的发票
export const lookInvoiceNumber = (params) => {
  return getRequest("invoice/lookInvoiceNumber", params);
};
//开票商品明细
export const invoiceNumberProduct = (id, params) => {
  return getRequest("invoice/invoiceNumberProduct/" + id, params);
};
//发票信息列表
export const invoiceInfo = (params) => {
  return putRequest("invoice/invoice", params);
};
//新增开票信息
export const saveInvoice = (id, params) => {
  return postRequest("invoice/saveV2/" + id, params);
};
//发票详情
export const invoiceDetail = (id) => {
  return getRequest("invoice/detail/" + id);
};
//删除发票
export const invoiceDel = (id) => {
  return deleteRequest("invoice/del/" + id);
};
//设置默认发票
export const setDefault = (id, params) => {
  return postRequest("invoice/set_default/" + id, params);
};
//充值记录
export const userMoneyLog = (data) => {
  return getRequest("user/userMoneyLog", data);
};
//充值记录
export const useMoneyLog = (data) => {
  return getRequest("user/useMoneyLog", data);
};
//发送短信
export const getSmsCode = (data) => {
  return getRequest("user/pwd/sms", data);
};
//设置支付密码
export const editPwd = (data) => {
  return postRequest("user/editPwd", data);
};
//充值预付款
export const advancePayment = (data) => {
  return postRequest("recharge/advancePayment", data);
};
//发送短信
export const rechargeLog = (data) => {
  return getRequest("recharge/rechargeLog", data);
};
// 在线充值预付款
export const paymentInline = (money) => {
  return postRequest("recharge/recharge", { money });
};
