<template>
  <page-layout>
    <a-card>
      <a-row type="flex" justify="space-between" align="middle">
        <a-col>
          <div>我的账户余额</div>
          <div class="yue">￥{{ $store.state.user.userInfo.now_money }}</div>
        </a-col>
        <a-col>
          <a-button
            type="primary"
            style="
              margin-right: 16px;
              background-color: #ff8d1a;
              border-color: #ff8d1a;
            "
            @click="(visible2 = true), getBank()"
          >
            账户余额充值
          </a-button>
          <a-button type="primary" @click="visible = true">
            设置支付密码
          </a-button>
        </a-col>
      </a-row>
    </a-card>
    <a-card class="mt24">
      <a-tabs :animated="false" @change="changeTab">
        <a-tab-pane key="1" tab="充值记录"> </a-tab-pane>
        <a-tab-pane key="2" tab="使用记录"> </a-tab-pane>
        <a-tab-pane key="3" tab="审核记录"> </a-tab-pane>
      </a-tabs>
      <a-table
        v-show="tabType == 1 || tabType == 2"
        rowKey="id"
        :columns="tabType == 1 ? columns : columns2"
        :pagination="pagination"
        :loading="loading"
        :data-source="data"
        :scroll="{ x: 1000 }"
        @change="changePage"
      >
      </a-table>
      <a-table
        v-show="tabType == 3"
        rowKey="id"
        :columns="columns3"
        :pagination="pagination"
        :loading="loading"
        :data-source="data"
        :scroll="{ x: 1000 }"
        @change="changePage"
      >
        <template slot="img" slot-scope="text, record, index">
          <img
            :src="record.img"
            alt=""
            style="width: 48px; height: 48px; cursor: zoom-in"
            :preview="index"
            preview-text="付款凭证"
          />
        </template>
        <template slot="status" slot-scope="text, record">
          <div>
            {{
              record.paid == 0
                ? "待审核"
                : record.paid == 1
                ? "审核成功"
                : "审核失败"
            }}
          </div>
          <div v-if="record.paid == 2" style="color: #ff5733">
            失败原因：{{ record.fail_remark }}
          </div>
        </template>
      </a-table>
    </a-card>

    <a-modal
      title="设置支付密码"
      :visible="visible"
      @ok="handleOk"
      @cancel="visible = false"
    >
      <a-form-model
        ref="payForm"
        :model="payForm"
        :rules="rules"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-form-model-item label="支付密码" prop="cipher">
          <a-input-password
            v-model="payForm.cipher"
            placeholder="请输入六位数字支付密码"
          />
        </a-form-model-item>
        <a-form-model-item label="再次输入支付密码" prop="affirmCipher">
          <a-input-password
            v-model="payForm.affirmCipher"
            placeholder="请再次输入支付密码"
          />
        </a-form-model-item>
        <a-form-model-item label="绑定手机号">
          <div style="display: flex; align-items: center; gap: 8px">
            <span>{{ $store.state.user.userInfo.phone | phoneFour }}</span>
            <a-button
              :disabled="btnText != '获取短信验证码'"
              @click="imageCodeVisible = true"
            >
              {{ btnText }}
            </a-button>
          </div>
        </a-form-model-item>
        <a-form-model-item label="短信验证码" prop="code">
          <a-input placeholder="请输入短信验证码" v-model="payForm.code" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal
      v-model="visible2"
      title="账户余额充值"
      width="600px"
      :okText="rechargeForm.payType == 3 ? '立即支付' : '提交审核'"
      @ok="submitRecharge"
    >
      <a-form-model
        ref="rechargeForm"
        :model="rechargeForm"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-form-model-item
          label="充值方式"
          prop="payType"
          :rules="[{ required: true, message: '请选择充值方式' }]"
        >
          <a-select
            v-model="rechargeForm.payType"
            style="width: 100%"
            placeholder="请选择充值方式"
          >
            <a-select-option :value="3">在线扫码充值</a-select-option>
            <a-select-option :value="1">线下银行转账</a-select-option>
            <a-select-option :value="2">线下扫码充值</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          label="充值金额(元)"
          prop="money"
          :rules="[{ required: true, message: '请输入充值金额' }]"
        >
          <a-input-number
            v-model="rechargeForm.money"
            placeholder="请输入充值金额"
            style="width: 100%"
            :min="0"
            :max="9999999"
            :step="0.1"
          />
        </a-form-model-item>
        <template v-if="rechargeForm.payType != 3">
          <a-form-model-item
            label="充值日期"
            prop="payTime"
            :rules="[{ required: true, message: '请选择充值日期' }]"
          >
            <a-date-picker
              v-model="date"
              style="width: 100%"
              :disabled-date="disabledDate"
              @change="onPayDateChange"
            />
          </a-form-model-item>
          <a-form-model-item
            label="收款账户"
            prop="curBankIndex"
            :rules="[
              {
                required: true,
                message: '请选择收款账户',
                trigger: 'change',
                type: 'number',
                validator: validateBank,
              },
            ]"
          >
            <a-select
              style="width: 100%"
              v-model="value"
              placeholder="请选择收款账户"
              @change="changeBank"
            >
              <a-select-opt-group
                v-for="(item, index) in bankList"
                :key="index"
              >
                <span slot="label">{{ item.bank_name }}：</span>
                <a-select-option :value="index">
                  {{ item.bank_no }}
                </a-select-option>
              </a-select-opt-group>
            </a-select>
            <div v-if="rechargeForm.curBankIndex >= 0" class="bank-view">
              <div class="bank-cell">
                <div class="flex-none">开户行</div>
                <div class="flex-auto text-right">
                  {{ bankList[rechargeForm.curBankIndex].bank_name || "-" }}
                </div>
                <a-icon
                  v-if="bankList[rechargeForm.curBankIndex].bank_name"
                  class="flex-none"
                  type="copy"
                  style="cursor: pointer"
                  @click="
                    copyText(bankList[rechargeForm.curBankIndex].bank_name)
                  "
                />
              </div>
              <div class="bank-cell">
                <div class="flex-none">账户名</div>
                <div class="flex-auto text-right">
                  {{
                    bankList[rechargeForm.curBankIndex].bank_account_name || "-"
                  }}
                </div>
                <a-icon
                  v-if="bankList[rechargeForm.curBankIndex].bank_account_name"
                  class="flex-none"
                  type="copy"
                  style="cursor: pointer"
                  @click="
                    copyText(
                      bankList[rechargeForm.curBankIndex].bank_account_name
                    )
                  "
                />
              </div>
              <div class="bank-cell">
                <div class="flex-none">账号</div>
                <div class="flex-auto text-right">
                  {{ bankList[rechargeForm.curBankIndex].bank_no || "-" }}
                </div>
                <a-icon
                  v-if="bankList[rechargeForm.curBankIndex].bank_no"
                  class="flex-none"
                  type="copy"
                  style="cursor: pointer"
                  @click="copyText(bankList[rechargeForm.curBankIndex].bank_no)"
                />
              </div>
            </div>
          </a-form-model-item>
          <a-form-model-item
            label="上传付款凭证"
            prop="img"
            :rules="[{ required: true, message: '请上传付款凭证' }]"
          >
            <a-upload
              name="avatar"
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              :customRequest="customRequest"
            >
              <img
                v-if="rechargeForm.img"
                :src="rechargeForm.img"
                alt="avatar"
                style="width: 102px; height: 102px"
              />
              <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">上传</div>
              </div>
            </a-upload>
          </a-form-model-item>
          <a-form-model-item label="备注说明">
            <a-textarea
              v-model="rechargeForm.remark"
              placeholder="请输入备注说明"
              :auto-size="{ minRows: 3, maxRows: 5 }"
            />
          </a-form-model-item>
          <a-alert message="人工审核时间为: 工作日 08:30 - 17:30" banner />
        </template>
      </a-form-model>
    </a-modal>

    <imageCodeAlert
      v-model="imageCodeVisible"
      @confirm="handleImageCodeConfirm"
    />
  </page-layout>
</template>

<script>
var timer = null;
var newWindow = null;
import PageLayout from "@/layouts/PageLayout";
import {
  userMoneyLog,
  useMoneyLog,
  getSmsCode,
  editPwd,
  advancePayment,
  rechargeLog,
  paymentInline,
} from "@/api/invoice";
import { getBankAll, uploadImg } from "@/api/myOrder";
import moment from "moment";
import imageCodeAlert from "@/components/imageCodeAlert/index.vue";

export default {
  components: { PageLayout, imageCodeAlert },
  name: "",
  data() {
    let validatePass = (rule, value, callback) => {
      if (value.length != 6) {
        callback("支付密码为六位数");
      } else if (value != this.payForm.cipher) {
        callback("两次密码输入不一致");
      } else {
        callback();
      }
    };
    let validateBank = (rule, value, callback) => {
      if (value < 0) {
        callback(new Error("请选择收款账户"));
      } else {
        callback();
      }
    };
    return {
      columns,
      columns2,
      validateBank,
      data: [],
      form: {
        page: 1,
        limit: 10,
      },
      tabType: 1,
      loading: false,
      pagination: {
        total: 0,
      },
      visible: false,
      btnText: "获取短信验证码",
      payForm: {},
      rules: {
        cipher: [
          { required: true, message: "请输入支付密码", trigger: "blur" },
          {
            len: 6,
            message: "支付密码为六位数",
            trigger: "blur",
          },
        ],
        affirmCipher: [
          { required: true, message: "请输入支付密码", trigger: "blur" },
          {
            validator: validatePass,
            trigger: "blur",
          },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      visible2: false,
      rechargeForm: {
        payType: 3,
        payTime: "",
        curBankIndex: -1,
        img: "",
      },
      bankList: [],
      columns3,
      date: undefined,
      value: undefined,
      disabledDate(current) {
        return current && current >= moment().endOf("day");
      },
      imageCodeVisible: false,
    };
  },
  created() {
    this.getList();
    window.addEventListener("beforeunload", this.onBeforeunload);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.onBeforeunload);
  },
  filters: {
    phoneFour(val) {
      return val && val.length == 11
        ? val.slice(0, 3) + "****" + val.slice(7, 11)
        : "";
    },
  },
  methods: {
    moment,
    onBeforeunload() {
      if (newWindow) {
        newWindow.close();
      }
    },
    getList() {
      this.loading = true;
      let url =
        this.tabType == 1
          ? userMoneyLog
          : this.tabType == 2
          ? useMoneyLog
          : rechargeLog;
      url(this.form)
        .then((res) => {
          this.data = res.data.data.list;
          this.pagination.total = res.data.data.count;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changeTab(e) {
      this.tabType = e;
      this.form = {
        page: 1,
        limit: 10,
      };
      this.pagination.current = 1;
      this.pagination.pageSize = 10;

      this.getList();
    },
    customRequest({ file }) {
      if (file.type != "image/jpeg" && file.type != "image/png") {
        this.$message.error("请上传JPG或PNG格式文件");
        return false;
      }
      new Promise((resolve) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result);
          this.baseImageUrl = fileReader.result;
          this.upLoadImgs();
        };
      });
    },
    upLoadImgs() {
      uploadImg({ file: this.baseImageUrl }).then((res) => {
        this.rechargeForm.img = res.data.data.url;
      });
    },
    getBank() {
      getBankAll().then((res) => {
        const bankList = res.data.data || [];
        bankList.forEach((item) => {
          const array = item.bank_name.split("-") || [];
          if (array.length > 1) {
            item.bank_name = array[0];
            item.bank_account_name = array[1];
          } else {
            item.bank_account_name = "";
          }
        });
        this.bankList = bankList;
      });
    },
    changeBank(index) {
      this.rechargeForm.curBankIndex = index;
    },
    onPayDateChange(date, dateString) {
      this.rechargeForm.payTime = dateString;
    },
    submitRecharge() {
      this.$refs.rechargeForm.validate((valid) => {
        if (!valid) {
          return;
        }
        if (this.rechargeForm.payType == 3) {
          return this.onGoPay();
        }
        advancePayment({
          ...this.rechargeForm,
          bankName: this.bankList[this.rechargeForm.curBankIndex].bank_name,
          bankSn: this.bankList[this.rechargeForm.curBankIndex].bank_no,
        }).then((res) => {
          if (res.data.status == 200) {
            this.visible2 = false;
            this.rechargeForm = {};
            this.date = undefined;
            this.value = undefined;
            this.$message.success(res.data.msg);
          } else {
            this.$message.error(res.data.msg);
            this.value = undefined;
            this.date = undefined;
            this.rechargeForm = {};
            this.visible2 = false;
          }
        });
      });
    },
    // 发起支付
    onGoPay() {
      if (!this.rechargeForm.money) {
        return this.$message.warning("请输入充值金额");
      }
      newWindow = window.open("");
      paymentInline(this.rechargeForm.money)
        .then((res) => {
          if (res.data.status == 200) {
            this.visible2 = false;
            // newWindow = window.open(res.data.data.sytUrl, "_blank");
            newWindow.location = res.data.data.sytUrl;
            this.$info({
              title: "支付提示",
              content: "请在新标签页中进行支付",
              okText: "我已支付",
              showCancel: false,
              keyboard: false,
              maskClosable: false,
              closable: false,
              onOk: () => {
                this.$store.dispatch("getUserInfo");
                newWindow.close();
                newWindow = null;
              },
            });
          } else {
            this.$message.error(res.data.msg);
            newWindow.close();
            newWindow = null;
          }
        })
        .catch(() => {
          newWindow.close();
          newWindow = null;
        });
    },
    handleImageCodeConfirm(val) {
      getSmsCode({ key: val.key, code: val.code }).then((res) => {
        this.$message.success(res.data.msg);
        let time = 60;
        timer = setInterval(() => {
          time--;
          this.btnText = `${time}S 后再次获取`;
          if (time == 0) {
            clearInterval(timer);
            this.btnText = "获取短信验证码";
          }
        }, 1000);
      });
    },
    handleOk() {
      this.$refs.payForm.validate((valid) => {
        if (valid) {
          console.log(this.payForm);
          editPwd(this.payForm).then(() => {
            this.visible = false;
            this.payForm = {};
            this.$message.success("修改成功");
          });
        } else {
          return false;
        }
      });
    },
    changePage(pagination) {
      console.log(pagination);
      this.pagination = pagination;
      this.form.page = pagination.current;
      this.form.limit = pagination.pageSize;
      this.getList();
    },
    async copyText(text) {
      if (!text) {
        return;
      }
      try {
        const textToCopy = text;
        await navigator.clipboard.writeText(textToCopy);
        this.$message.success("复制成功");
      } catch (err) {
        console.error("复制失败", err);
      }
    },
  },
};
const columns = [
  {
    title: "变动类型",
    key: "pm",
    dataIndex: "pm",
    customRender: (text, record) => {
      return record.pm == 0 ? "减少" : "增加";
    },
    align: "center",
  },
  { title: "操作人", key: "relation", dataIndex: "relation", align: "center" },
  {
    title: "充值金额",
    key: "number",
    dataIndex: "number",
    customRender: (text, record) => {
      return (record.pm == 0 ? "-" : "+") + record.number;
    },
    align: "center",
  },
  { title: "充值前余额", key: "front", dataIndex: "front", align: "center" },
  {
    title: "充值后余额",
    key: "balance",
    dataIndex: "balance",
    align: "center",
  },
  {
    title: "充值时间",
    key: "add_time",
    dataIndex: "add_time",
    align: "center",
  },
  { title: "备注", key: "mark", dataIndex: "mark", align: "center" },
];
const columns2 = [
  {
    title: "变动类型",
    key: "pm",
    dataIndex: "pm",
    customRender: (text, record) => {
      return record.pm == 0 ? "减少" : "增加";
    },
    align: "center",
  },
  { title: "操作人", key: "relation", dataIndex: "relation", align: "center" },
  {
    title: "扣除金额",
    key: "number",
    dataIndex: "number",
    customRender: (text, record) => {
      return (record.pm == 0 ? "-" : "+") + record.number;
    },
    align: "center",
  },
  { title: "剩余金额", key: "balance", dataIndex: "balance", align: "center" },
  {
    title: "订单编号",
    key: "order_sn",
    dataIndex: "order_sn",
    align: "center",
    width: 200,
  },
  {
    title: "扣除时间",
    key: "add_time",
    dataIndex: "add_time",
    align: "center",
  },
  { title: "备注", key: "mark", dataIndex: "mark", align: "center" },
];
const columns3 = [
  {
    title: "充值日期",
    key: "pay_time",
    dataIndex: "pay_time",
    align: "center",
  },
  {
    title: "充值方式",
    key: "recharge_type",
    dataIndex: "recharge_type",
    align: "center",
    scopedSlots: { customRender: "recharge_type" },
  },
  {
    title: "充值金额",
    key: "price",
    dataIndex: "price",
    align: "center",
    customRender: (text) => {
      return "￥" + text;
    },
  },
  {
    title: "收款账户",
    key: "bank_name",
    dataIndex: "bank_name",
    align: "center",
  },
  {
    title: "付款凭证",
    key: "img",
    dataIndex: "img",
    align: "center",
    scopedSlots: { customRender: "img" },
  },
  {
    title: "申请时间",
    key: "add_time",
    dataIndex: "add_time",
    align: "center",
  },
  { title: "备注说明", key: "remark", dataIndex: "remark", align: "center" },
  {
    title: "审核状态",
    key: "status",
    dataIndex: "status",
    align: "center",
    scopedSlots: { customRender: "status" },
  },
];
</script>
<style scoped>
.mt24 {
  margin-top: 24px;
}

.yue {
  font-size: 24px;
  color: #505050;
  font-weight: bold;
}

.bank-view {
  width: 100%;
  background-color: rgb(247, 248, 250);
  border-radius: 8px;
  padding: 12px;
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 12px;
  line-height: 1;
}
.bank-cell {
  display: flex;
  gap: 12px;
  justify-content: space-between;
}
.flex-none {
  flex: none;
}
.flex-auto {
  flex: auto;
}
.text-right {
  text-align: right;
}
</style>
